import React from 'react';
import { Route, Switch } from "react-router-dom";
import Home from './pages/home'
import Discord from './pages/discord'
import notFound from './pages/notFound'
import Teach from './pages/teach'
import Projekty from './pages/projecty';
function App() {
  return (
        <div className='Routers' id='Routers'>
          <Switch>
            <Route exact  path="/" component={Home}/>
            <Route exact  path="/discord" component={Discord}/>
            <Route exact  path="/projekty" component={Projekty}/>
            <Route exact  path="/teach" component={Teach}/>
            <Route exact  path="*" component={notFound}/>
          </Switch>
        </div>
  );
}

export default App;
