/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import '../../assets/css/footer.css';
function App() {
	return (
		<div>
			<div className="footer" style={{ height: '75px' }}>
				<div className="footer-divs">
					<div className="footer-div">
					&nbsp;&nbsp;<a target="about" href="/PrivacyPolicy.pdf">Privacy Policy</a>
					</div>
					<div className="footer-div">
						<a target="about" href="/TermsOfService.pdf">Terms of Service</a>
					</div>
					<div className="footer-div">
						<a href="mailto:support@michlip.eu">Contact: support@michlip.eu</a>&nbsp;&nbsp;&nbsp;&nbsp;
					</div>
				</div>
			</div>
			<div className="footer">
				<div className="footer-divs">
					<div className="footer-div">
						<div className="SocialMedias">
							<a target="about" href="https://michlip.eu/discord">
								<div className="SocialMedia">
									<img
										className="SocialMedia-img"
										src="https://cdn.michlip.eu/svg/discord.svg"
									/>
								</div>
							</a>&nbsp;&nbsp;
							<a
								target="about"
								href="https://www.tiktok.com/@michlip_programming"
							>
								<div className="SocialMedia">
									<img
										className="SocialMedia-img"
										src="https://cdn.michlip.eu/svg/tiktok.svg"
									/>
								</div>
							</a>
						</div>
						&nbsp;&nbsp;Web vytvořilo Vedení Michlipu
					</div>
					<div className="footer-div">
						<div className="SocialMedias">
							<a target="about" href="https://michlip.eu/discord">
								<div className="SocialMedia">
									<img
										className="SocialMedia-img"
										src="https://cdn.michlip.eu/svg/discord.svg"
									/>
								</div>
							</a>&nbsp;&nbsp;
							{/* <a target="about" href="https://www.instagram.com/__misaliba__/">
                                <div className="SocialMedia">
                                    <img className="SocialMedia-img" src="https://cdn.michlip.eu/svg/instagram.svg"></img>
                                </div>
                            </a>&nbsp;&nbsp;
                            <a target="about" href="https://www.tiktok.com/@misaliba">
                                <div className="SocialMedia">
                                    <img className="SocialMedia-img" src="https://cdn.michlip.eu/svg/tiktok.svg"></img>
                                </div>
                            </a> */}
						</div>
						michlip.eu ©️ 2021 - {new Date().getFullYear()} Všechna práva
						vyhrazena&nbsp;&nbsp;
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
