/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {Helmet} from "react-helmet";
import '../../assets/css/home.css';

function Projekty() {
    return (
        <div>
            <Helmet>
                <title>Projekty | Michlip.eu</title>
            </Helmet>
            <div className="project-home">
                    <img className="logo" src="https://cdn.michlip.eu/img/michlip.png"></img>
                    <div className="space"></div>
                    <div className="projects">
                    <div className="project">
                            <h3>Arsenus Bot</h3>
                            <h4>Arsenus je náš discord bot, který je jako mee6 atd ale s tím rozdímem že má téměř neomezené limitace.</h4>
                            <div className="buttons">
                                <a className="blue" target="about" href="//arsenus.michlip.eu">Pozvat na server</a>
                            </div>
                        </div>
                        <div className="project">
                            <h3>michlip uptime</h3>
                            <h4>Michlip uptime se snaží co nejvíce přiblížit k uptimerobot.com a k tomu mít téměř neomezené využití.</h4>
                            <div className="buttons">
                                <a className="green" target="about" href="//uptime.michlip.eu">Naštívit</a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
      );
}

export default Projekty;