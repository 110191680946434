/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import {Helmet} from "react-helmet";
import '../../assets/css/home.css';
import paypal from '../../assets/css/paypal.webp';

function Home() {
          return (
        <div>
            <Helmet>
                <title>Domov | Michlip.eu</title>
            </Helmet>
            <div className="home">
                <div className="homeCenter">
                    <img className="logo" src="https://cdn.michlip.eu/img/michlip.png"></img>
                    <h2>Ahoj jsme Česko Slovenská programátorská discord komunita, <br></br>u které se nemusíš bát zeptat na cokoliv.</h2>
                </div>
            </div>
            <style>{`
            .homeDonation {
                background-color: #2f3136;
                border-radius: 10px;
                padding: 10px;
                margin-top: 20px;
                width: 100%;
                height: 100%;
                max-width: 400px;
                max-height: 200px;
                overflow: hidden;
            }
            .homeDonation h3 {
                color: #fff;
                font-size: 20px;
                margin-bottom: 10px;
            }
            .homeDonationCenter {
                display: flex;
                align-items: center;
            }
            .homeDonationProgressBar {
                background-color: #fff;
                height: 10px;
                width: 100%;
                border-radius: 10px;
                margin-right: 10px;
            }
            .homeDonationProgressBarFill {
                background-color: #4ef542;
                height: 10px;
                width: 0%;
                border-radius: 10px;
            }
            .homeDonationProgressText {
                color: #fff;
                font-size: 15px;
            }
            .homeDonation a {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 75px;
                overflow: hidden;
            }
            .homeDonation a img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
            `}</style>
        </div>
      );
}

export default Home;