/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {Helmet} from "react-helmet";
import { Redirect } from "react-router-dom";
import '../../assets/css/home.css';

function Discord() {
    return (
      <Redirect to="/" />
    )
}

export default Discord;