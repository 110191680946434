import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/index.js';
import { BrowserRouter } from "react-router-dom";

import Navbar from './pages/utils/Navbar'
import Footer from './pages/utils/footer'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Navbar/>
    <App />
  <Footer/>
  </BrowserRouter>
);
