/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {Helmet} from "react-helmet";
import '../../assets/css/home.css';

function Teach() {
    return (
        <div>
            <Helmet>
                <title>Teach | Michlip.eu</title>
            </Helmet>
            <div className="home">
                <div className="homeCenter">
                    <img className="logo" src="https://cdn.michlip.eu/img/michlip.png"></img>
                    <h2>Tady zatím nic není.</h2>
                </div>
            </div>
        </div>
      );
}

export default Teach;