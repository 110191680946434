import React from "react";
import { Link } from 'react-router-dom'
import '../../assets/css/navbar.css'

function App() {
    return (
        <div>
            <div className="Navbar">
                <div className="title"><Link to="/" className="titleText">michlip.eu</Link></div>
                <div className="lists">
                    <div className="list">
                        <Link to="/" className="listText">Domov</Link>
                    </div>
                    <div className="list">
                        <a target="_blank" href="/discord" className="listText">Discord</a>
                    </div>
                    <div className="list">
                        <Link to="/teach" className="listText">Teach</Link>
                    </div>
                    <div className="list">
                        <Link to="/projekty" className="listText">Projekty</Link>
                    </div>
                    {/* <div className="list">
                        <Link to="/dino" className="listText">Dino</Link>
                    </div> */}
                    <div className="list">
                    </div>
                </div>
            </div>
        </div>
      );
}

export default App;